import React, { useEffect, useRef, useState, useContext } from 'react'
import { Dialog, DialogTitle, DialogContent, Fab } from "@mui/material";
import DataTable from '../components/Common/DataTable';
import { Card } from 'react-bootstrap';
import SearchComponent from '../components/Search/Search';
import DrawerComponent from '../components/Drawer/Drawer';
import Sidebar from '../components/Drawer/Sidebar';
import { useDispatch, useSelector } from 'react-redux';
import { Draw, Modify, Snap } from "ol/interaction";
import TileLayer from 'ol/layer/Tile';
import { OSM,TileWMS  } from 'ol/source';
import VectorLayer from 'ol/layer/Vector';
import { Map, Feature, View } from "ol";
import { fromLonLat, transform } from 'ol/proj';
import { setDrawerOpen, setSelectedCoordinates } from '../redux/actions/drawerActions';
import VectorSource from 'ol/source/Vector';
import { Point } from 'ol/geom';
import { notify } from '../utils/notify';
import Toolbar from '../components/Toolbar/Toolbar';
import Footer from './Footer';
import FloatingToolbar from './FloatingToolbar';
import BingMaps from 'ol/source/BingMaps';
import XYZ from 'ol/source/XYZ';
import { ColorContext } from 'ColorContext';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import NorthArrowControl from 'components/NorthControl';
import { defaults as defaultControls, FullScreen, ScaleLine } from 'ol/control';

import { useMap } from '../hooks/useMap';

const Home = () => {
  const { backgroundColor, textColor } = useContext(ColorContext);
  const isAuthenticated = useSelector(state => state?.auth?.isAuthenticated);
  const dispatch = useDispatch();

  const [baseMapSource, setBaseMapSource] = useState(
    new XYZ({
      url: 'https://api.mapbox.com/styles/v1/mapbox/dark-v10/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiZ2lzdGVjaCIsImEiOiJjajdxMXdvb3YzY2hiMzN0NWhjb2VkYWNlIn0.bkcli-wKDsl3wCDOdO27rA'
    })
  );

  const { mapRef, vectorSource } = useMap(baseMapSource);

  const handleBaseMapChange = (mapType) => {
    let newBaseMapSource;
    setBaseMapSource(newBaseMapSource);
  };

  const handleGPSClick = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        const coords = fromLonLat([longitude, latitude]);

        if (mapRef.current) {
          const view = mapRef.current.getView();
          view.setCenter(coords);
          view.setZoom(14);
        }
      }, (error) => {
        console.error("Error fetching location: ", error);
      });
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const handleSearch = (event) => {
    const coordinates = event;
    if (mapRef.current && coordinates && coordinates.length === 2) {
      const [lon, lat] = coordinates;
      const center = fromLonLat([lon, lat]);
      const view = mapRef.current.getView();

      view.setCenter(center);
      view.setZoom(12);

      setTimeout(() => {
        mapRef.current.renderSync();
        mapRef.current.updateSize();
      }, 500);
    } else {
      console.error(
        "Map or coordinates are not available or invalid:",
        mapRef.current,
        coordinates
      );
    }
  };

  const handleSubmit = (formData) => {

  }

  const handleReset = () => {
    vectorSource.clear();
    dispatch(setSelectedCoordinates(null));
    dispatch(setDrawerOpen(false));
  };
  

  return (
    <>
      <Toolbar />
      <div id="map" className="map" style={{ backgroundColor }}></div>
       <Card style={{ width: "18rem", top: "5em", left: "0.5em", backgroundColor: backgroundColor, color: textColor }}>
        <Card.Body>
          <Card.Title></Card.Title>
          <SearchComponent map={mapRef.current} />
        </Card.Body>
      </Card>
      <FloatingToolbar onBaseMapChange={handleBaseMapChange} map={mapRef.current} />
      <Sidebar onClose={handleReset} onSubmit={handleSubmit} onReset={handleReset} map={mapRef.current} />
      <Fab color="primary" aria-label="gps" style={{ position: 'absolute', bottom: 20, right: 450 }} onClick={handleGPSClick}>
        <GpsFixedIcon />
      </Fab>
      <Footer />
    </>
  );
};

export default Home;
