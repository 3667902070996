export const SET_TOWN_NAME = 'SET_TOWN_NAME';
export const SET_TOWN_COORDINATES = 'SET_TOWN_COORDINATES';

export const setTownName = (townName) => ({
  type: SET_TOWN_NAME,
  payload: townName,
});

export const setTownCoordinates = (coordinates) => ({
  type: SET_TOWN_COORDINATES,
  payload: coordinates, 
});
