import React, { useContext, useRef } from 'react';
import { useSelector } from 'react-redux';
import { IconButton, Tooltip, FormControl, MenuItem, Menu, Select } from '@mui/material';
import { ColorContext } from 'ColorContext';
import { LineString } from 'ol/geom';
import { Feature } from 'ol';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { Stroke, Style } from 'ol/style';

import FullscreenIcon from '@mui/icons-material/Fullscreen';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import MapIcon from '@mui/icons-material/Map';
import RateReviewIcon from '@mui/icons-material/RateReview';
import CalendarViewDayIcon from '@mui/icons-material/CalendarViewDay';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import HomeIcon from '@mui/icons-material/Home';

const FloatingToolbar = ({ onBaseMapChange, map }) => {
  const { backgroundColor, textColor } = useContext(ColorContext);
  const routeLayerRef = useRef(null);
  const { townName, coordinates } = useSelector((state) => state.town);

  const printMap = () => {
    setTimeout(() => {
      window.print();
    }, 4000);
  };

  const displayRoute = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async (position) => {
        const userCoordinates = [position.coords.longitude, position.coords.latitude];

        if (coordinates) {
          const url = `https://router.project-osrm.org/route/v1/driving/${userCoordinates.join(',')};${coordinates.join(',')}?overview=full&geometries=geojson`;
          
          try {
            const response = await fetch(url);
            const data = await response.json();

            if (data.routes.length > 0) {
              const routeGeoJSON = data.routes[0].geometry;

              if (routeLayerRef.current) {
                map.removeLayer(routeLayerRef.current);
              }

              const routeFeature = new Feature({
                geometry: new LineString(routeGeoJSON.coordinates).transform('EPSG:4326', 'EPSG:3857')
              });

              routeFeature.setStyle(new Style({
                stroke: new Stroke({
                  color: '#ffcc33',
                  width: 4
                })
              }));

              const routeLayer = new VectorLayer({
                source: new VectorSource({
                  features: [routeFeature]
                })
              });

              map.addLayer(routeLayer);

              routeLayerRef.current = routeLayer;

              const extent = routeLayer.getSource().getExtent();
              map.getView().fit(extent, { duration: 1000 });
            }
          } catch (error) {
            console.error('Error fetching route:', error);
          }
        } else {
          console.error('No town selected.');
        }
      });
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  };

  return (
    <div style={{
      position: 'fixed',
      top: '70px',
      right: '35%',
      backgroundColor: backgroundColor || 'rgba(29, 45, 55, 0.8)',
      padding: '10px',
      borderRadius: '8px',
      display: 'flex',
      alignItems: 'center',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)'
    }}>
      <Tooltip title="Home">
        <IconButton style={{ color: textColor || '#fff' }}>
          <HomeIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Full Screen map">
        <IconButton style={{ color: textColor || '#fff' }}>
          <FullscreenIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Print Map">
        <IconButton style={{ color: textColor || '#fff' }}>
          <LocalPrintshopIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="3D Map View">
        <IconButton style={{ color: textColor || '#fff' }}>
          <MapIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="View Feedback">
        <IconButton style={{ color: textColor || '#fff' }}>
          <RateReviewIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Add Feedback">
        <IconButton style={{ color: textColor || '#fff' }}>
          <CalendarViewDayIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Change basemap">
        <FormControl style={{ marginLeft: '10px', minWidth: '120px' }}>
          <Select
            onChange={(e) => onBaseMapChange(e.target.value)}
            defaultValue="mapbox"
            style={{ color: textColor || '#fff', backgroundColor: backgroundColor || '#1D2D37', borderColor: '#fff' }}
          >
            <MenuItem value="osm"  style={{ color: textColor || '#fff', backgroundColor: backgroundColor || '#1D2D37', borderColor: '#fff' }}
                >Open Street Map</MenuItem>
            <MenuItem style={{ color: textColor || '#fff', backgroundColor: backgroundColor || '#1D2D37', borderColor: '#fff' }}
                value="bing">Bing Maps</MenuItem>
            <MenuItem style={{ color: textColor || '#fff', backgroundColor: backgroundColor || '#1D2D37', borderColor: '#fff' }}
                value="mapbox">Night View</MenuItem>
          </Select>
        </FormControl>
      </Tooltip>
      <Tooltip title="Show Route">
        <IconButton style={{ color: textColor || '#fff' }} onClick={displayRoute}>
          <TransferWithinAStationIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default FloatingToolbar;
