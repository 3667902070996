import { combineReducers } from "redux";
import authReducer from "./authReducer";
import drawerReducer from "./drawerReducer";
import townReducer from "./townReducer";


// combine all the reducers here
export const rootReducer = combineReducers({
    auth: authReducer,
    drawer: drawerReducer,
    town: townReducer
})

export default rootReducer;