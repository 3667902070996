import { Control } from 'ol/control';
import React from 'react';
import ReactDOM from 'react-dom';
import { Button, styled } from "@mui/material";
import ExploreIcon from '@mui/icons-material/Explore';

const StyledButton = styled(Button)({
  position: 'absolute',
  bottom: '20px',  
  left: '20px',    
  zIndex: 1000,    
  backgroundColor: '#fff',
});

const NorthControl = /*@__PURE__*/ (function (Control) {
    let options = {};

    class NorthControl extends Control {
        constructor(opt_options) {
            super({
                element: document.createElement('div'),
                target: opt_options.target,
            });

            options = opt_options || {};

            const innerButton = (
                <StyledButton><ExploreIcon /></StyledButton>  
            );

            this.element.onclick = this.handleRotateNorth.bind(this);
            this.element.className = 'northControl ol-control';
            
            ReactDOM.render(innerButton, this.element);
        }

        handleRotateNorth() {
            options.olMapVM.getMap().getView().setRotation(0);  
        }
    }

    return NorthControl;
})(Control);

export default NorthControl;
