import React, { useContext } from 'react';
import { AppBar, Toolbar as MuiToolbar, Typography, IconButton, Box, Tooltip, Menu, MenuItem, Button } from '@mui/material';
import { ColorContext } from 'ColorContext'; 
import ColorLensIcon from '@mui/icons-material/ColorLens';
import { useDispatch } from 'react-redux';
import { logout } from '../../redux/actions/authActions';
import LogoutIcon from '@mui/icons-material/Logout';


const Toolbar = ({ handleLogout }) => {
  const { backgroundColor, textColor, setBackgroundColor, setTextColor } = useContext(ColorContext);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleColorChange = (type, color) => {
    if (type === 'background') {
      setBackgroundColor(color);
    } else if (type === 'text') {
      setTextColor(color);
    }
  };

  return (
    <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor }}>
      <MuiToolbar>
        {/* <img
          src="https://wirasat.com/wp-content/uploads/2024/07/Logo-Wirasat-Real-Estate.png"
          alt="Wirasat Real Estate Logo"
          style={{ height: '40px' }}
        /> */}
        <Typography variant="h6" sx={{ marginLeft: '20px', color: textColor }}>Plot Finder</Typography>
        <Box sx={{ flexGrow: 1 }} />

        <Tooltip title="Change Theme Colors">
          <IconButton style={{ color: textColor }} onClick={handleClick}>
            <ColorLensIcon />
          </IconButton>
        </Tooltip>

        {/* Dropdown Menu for Color Selection */}
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
          <MenuItem>
            <input
              type="color"
              value={backgroundColor}
              onChange={(e) => handleColorChange('background', e.target.value)}
              title="Background Color"
            />
            <Typography variant="body2" sx={{ marginLeft: 2 }}>Background</Typography>
          </MenuItem>
          <MenuItem>
            <input
              type="color"
              value={textColor}
              onChange={(e) => handleColorChange('text', e.target.value)}
              title="Text Color"
            />
            <Typography variant="body2" sx={{ marginLeft: 2 }}>Text</Typography>
          </MenuItem>
        </Menu>

        <Tooltip title="Logout">
          <IconButton style={{ color: textColor }} onClick={handleLogout}>
            <LogoutIcon />
          </IconButton>
        </Tooltip>
      </MuiToolbar>
    </AppBar>
  );
};

export default Toolbar;
