import React, { useState } from 'react';
import Slider from '@mui/material/Slider';
import XYZ from 'ol/source/XYZ';
import { Typography } from '@mui/material';


const TransparencySlider = ({ map }) => {
  const [transparency, setTransparency] = useState(100);

  const handleSliderChange = (event, newValue) => {
    setTransparency(newValue);

    map.getLayers().forEach((layer) => {
      const source = layer.getSource();

    if (!(source instanceof XYZ)) {
      layer.setOpacity(newValue / 100);  
    }
    });
  };

  return (
    <div>
      <Slider
        value={transparency}
        min={0}
        max={100}
        onChange={handleSliderChange}
        aria-labelledby="transparency-slider"
      />
      <Typography style={{color: 'white'}}>Layer Transparency: {transparency}%</Typography>
    </div>
  );
};

export default TransparencySlider;
