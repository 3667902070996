import { useState, useRef, useEffect } from 'react';
import { Map, View, Feature } from 'ol';
import TileLayer from 'ol/layer/Tile';
import { OSM, TileWMS, XYZ, BingMaps } from 'ol/source';
import VectorLayer from 'ol/layer/Vector';
import { fromLonLat, transform } from 'ol/proj';
import { Draw, Modify, Snap } from 'ol/interaction';
import { Point } from 'ol/geom';
import VectorSource from 'ol/source/Vector';
import { useDispatch } from 'react-redux';
import { setDrawerOpen, setSelectedCoordinates } from '../redux/actions/drawerActions';
import { defaults as defaultControls, FullScreen, ScaleLine } from 'ol/control';

import NorthArrowControl from 'components/NorthControl';

const vectorSource = new VectorSource();

export const useMap = (baseMapSource) => {
  const [mapInitialized, setMapInitialized] = useState(false);
  const mapRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!mapInitialized) {
      const initialMap = new Map({
        target: 'map',
        controls: defaultControls({ attribution: false }).extend([
          new FullScreen(),
          new NorthArrowControl({ olMapVM: mapInitialized }),
          new ScaleLine({ className: 'custom_scale', units: 'metric', bar: false })
        ]),
        layers: [
          new TileLayer({ source: baseMapSource }),
          new VectorLayer({ source: vectorSource }),
        ],
        view: new View({ center: fromLonLat([73.0363, 33.738045]), zoom: 8 })
      });

      const layers = ['viewer:Society_Boundaries', 'viewer:Bahria_1', 'viewer:Bahria_2'];
      const url = 'https://geoserver.naqshabook.xyz/geoserver/viewer/wms';

      layers.forEach(layerName => {
        const wmsLayer = new TileLayer({
          source: new TileWMS({
            url,
            params: { 'LAYERS': layerName, 'TILED': true },
            serverType: 'geoserver',
          }),
        });
        initialMap.addLayer(wmsLayer);
      });

      // const draw = new Draw({ source: vectorSource, type: 'Point' });
      // initialMap.addInteraction(draw);
      // initialMap.addInteraction(new Modify({ source: vectorSource }));
      // initialMap.addInteraction(new Snap({ source: vectorSource }));

      const handleClick = (event) => {
        const coords = event.coordinate;
        const transformedCoords = transform(coords, 'EPSG:3857', 'EPSG:4326');
        const feature = new Feature({ geometry: new Point(coords) });

        vectorSource.addFeature(feature);
        dispatch(setSelectedCoordinates(transformedCoords));
        dispatch(setDrawerOpen(true));
      };

      initialMap.on('click', handleClick);
      mapRef.current = initialMap;
      setMapInitialized(true);
    }
  }, [baseMapSource, mapInitialized, dispatch]);

  return { mapRef, vectorSource };
};
