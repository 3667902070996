// import { SET_TOWN_NAME, SET_TOWN_COORDINATES } from 'redux/actions/townActions';
import { SET_TOWN_NAME, SET_TOWN_COORDINATES } from '../../redux/actions/townActions';


const initialState = {
  townName: null,
  coordinates: null,
};

const townReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TOWN_NAME:
      return {
        ...state,
        townName: action.payload,
      };
    case SET_TOWN_COORDINATES:
      return {
        ...state,
        coordinates: action.payload,
      };
    default:
      return state;
  }
};

export default townReducer;
