import React, { useState, useEffect, useContext } from 'react';
import Autosuggest from 'react-autosuggest';
import axios from 'axios';
import { toast } from 'react-toastify';
import { fromLonLat } from 'ol/proj';  
import { ColorContext } from 'ColorContext'; 
import { useDispatch } from 'react-redux';
import { setTownCoordinates, setTownName } from '../../redux/actions/townActions';
// import { SET_TOWN_NAME, SET_TOWN_COORDINATES } from '../../redux/actions/townActions';


const SearchComponent = ({ map }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const { backgroundColor, textColor } = useContext(ColorContext);
  const dispatch = useDispatch();

  const fetchSuggestions = async (query) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/get-search-data/`, {
        params: { q: query }  
      });
      setSuggestions(response.data);
    } catch (error) {
      toast.error('Failed to fetch towns.');
    }
  };
  

  const handleSuggestionsFetchRequested = ({ value }) => {
    if (value.length > 1) { 
      fetchSuggestions(value);
    }
  };

  const handleSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const getSuggestionValue = (suggestion) => suggestion.town_name;

  const renderSuggestion = (suggestion) => (
    <div style={{ padding: '10px' }}>
      {suggestion.town_name}
    </div>
  );

  const handleSuggestionSelected = (event, { suggestion }) => {
    const { x, y, town_name } = suggestion; 
    dispatch(setTownName (town_name));
    dispatch(setTownCoordinates([x, y]));

    handleSearch([x, y]);  
  };

  const handleSearch = (coordinates) => {
    if (map && coordinates && coordinates.length === 2) {
      const [lon, lat] = coordinates;
      const center = fromLonLat([lon, lat]); 
      const view = map.getView();

      view.setCenter(center);
      view.setZoom(18);

      setTimeout(() => {
        map.renderSync();
        map.updateSize();
      }, 500);
    } else {
      console.error("Map or coordinates are not available or invalid:", map, coordinates);
    }
  };

  const inputProps = {
    placeholder: 'Search Town',
    value: searchQuery,
    style: {
      backgroundColor,
      color: textColor,
      border: `1px solid ${textColor}`,
      padding: '10px',
      borderRadius: '4px',
    },
    onChange: (e, { newValue }) => setSearchQuery(newValue), 
  };

  return (
    <div style={{ backgroundColor }}>
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
        onSuggestionsClearRequested={handleSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        onSuggestionSelected={handleSuggestionSelected}
        inputProps={inputProps}
      />
    </div>
  );
};

export default SearchComponent;
